import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import * as _ from 'lodash';
import { Store } from 'src/app/core/services/store.service';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'simple-multiselect',
  templateUrl: './simple-multiselect.component.html',
  providers: [{ provide: FormElementComponent, useExisting: forwardRef(() => SimpleMultiselectComponent) }],
})
export class SimpleMultiselectComponent extends FormElementComponent {
  @Input()
  data?: any[];

  filteredData: any[] = [];

  @ViewChild('multiselect', { static: false })
  multiselect: MultiSelectComponent;

  @Input()
  itemDisabled?: (any) => boolean;

  @Output()
  removeTag?: EventEmitter<any> = new EventEmitter();

  constructor(controlDir: NgControl, store: Store) {
    super(controlDir, store);
  }

  ngOnInit(): void {
    this.handleFilter('');
  }

  public onOpen() {
    this.handleFilter('');
  }

  tagRemoved(event) {
    this.removeTag.emit(event);
  }

  public focus() {
    if (this.multiselect) {
      setTimeout(() => {
        this.multiselect.focus();
      });
    }
  }

  handleFilter(text) {
    let filtered = this.data
      ? this.data.filter((value) => {
          if (typeof value !== 'string' && typeof value['label'] !== 'string') return false;
          return typeof value === 'string' ? value.toLocaleLowerCase().includes(text.toLocaleLowerCase()) : value['label'].toLocaleLowerCase().includes(text.toLocaleLowerCase());
        })
      : [];
    this.filteredData = filtered;
  }

  public neverDisable() {
    return false;
  }

  comparator(a: any[] | null, b: any[] | null) {
    if (!a && !b) return true;
    if ((!a && !!b) || (!!a && !b)) return false;
    return _.isEqual(a, b);
  }
}
