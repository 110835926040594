import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { Subset } from 'src/lib/generics';
import { Contact, SourceEntityTypeEntityNameMap, UserGroup } from 'src/lib/newBackendTypes';
import { ListNotifications } from 'src/lib/newBackendTypes/notificationTypes/listNotifications';
import { NotificationStatus } from 'src/lib/newBackendTypes/notificationTypes/notificationsStatus';
import { DelegateService } from './delegate-service.service';
import { ThalosApiService } from './thalos-api.service';
import { ThalosMessageToastComponent } from 'src/app/+modules/+it/containers/users-information-dashboard/users-information-messages/thalos-message-toast/thalos-message-toast.component';
import { ToastStyle } from './notification.service';
import { DefaultMessage } from 'src/app/+modules/+it/containers/users-information-dashboard/users-information-messages/users-information-messages.component';

@Injectable()
export class SocketIOService {
  socket: Socket;
  newerEntityVersion: EntityVersionEvent;
  notifications: ListNotifications[] = [];
  notificationsLoadedCount: number = 0;
  notificationsNotReadCount: number | null = null;
  allNotificationsCounter: number | null = null;
  notificationsInfo: EventEmitter<NotificationsInfo> = new EventEmitter();
  newerEntityVersionInfo: EventEmitter<EntityVersionEvent> = new EventEmitter();
  onlineUsersInfo: EventEmitter<OnlineUsersInfo> = new EventEmitter();
  thalosMessageData: ThalosMessage | null = null;

  constructor(protected api: ThalosApiService, protected router: Router, private delegateService: DelegateService) {}

  connect(user: Contact, entityVersionEvent: EntityVersionEvent) {
    this.socket = io(environment.apiHost, { transports: ['websocket'] });

    this.socket.on(WEB_SOCKET_CONNECTION, () => {
      const message: UserConnectInfo = {
        userId: user.id,
        username: user.displayName,
        firstName: user.firstName,
        lastName: user.lastName,
        channelId: entityVersionEvent.channelId,
        entityId: entityVersionEvent.entityId,
        entityNumber: entityVersionEvent.entityNumber,
        entityType: entityVersionEvent.entityType,
        url: entityVersionEvent.url,
      };
      this.socket.emit(WEB_SOCKET_THALOS_NOTIFICATIONS_EVENT, message);
    });

    this.socket.on(WEB_SOCKET_THALOS_GET_USERS_EVENT, (webSocketSessions: SocketSessions[]) => {
      this.onlineUsersInfo.emit({ webSocketSessions });
    });

    this.socket.on(WEB_SOCKET_THALOS_NOTIFICATIONS_EVENT, (notification: ListNotifications) => {
      this.notificationsFromSocket(notification);
    });

    this.socket.on(WEB_SOCKET_THALOS_MESSAGES_EVENT, (thalosMessage: ThalosMessage) => {
      const notificationService = this.delegateService.getService('notification');

      this.thalosMessageData = thalosMessage;

      notificationService.show({
        content: ThalosMessageToastComponent,
        position: { horizontal: 'center', vertical: 'top' },
        closable: true,
        animation: { type: 'slide', duration: 400 },
        type: { style: thalosMessage.toastStyle, icon: false },
      });
    });

    this.socket.on(WEB_SOCKET_THALOS_ENTITY_VERSION_EVENT, (msg: EntityVersionEvent) => {
      if (msg.entityType === entityVersionEvent.entityType && msg.entityId === entityVersionEvent.entityId && msg.channelId !== entityVersionEvent.channelId) {
        const promptService = this.delegateService.getService('prompt');

        promptService
          .simpleConfirmation(
            'Warning',
            `There is a newer version of this ${SourceEntityTypeEntityNameMap[msg.entityType]} that was updated by ${
              msg.username
            }, so your version is outdated. Click on "Refresh" to see the newer version, or "Close" to continue working with the previous one in read-only mode.`,
            {
              confirmText: 'Refresh',
              cancelText: 'Close',
              maxWidth: '723px',
            }
          )
          .subscribe((res) => {
            if (res) {
              this.router.navigate([this.router.url]);
            } else {
              this.newerEntityVersion = msg;
              this.newerEntityVersionInfo.emit(this.newerEntityVersion);
            }
          });
      }
    });
  }

  disconnect(user: Contact) {
    this.socket.disconnect();
  }

  notificationsFromSocket(notification: ListNotifications) {
    if (notification.status === NotificationStatus.SENT) this.notificationsNotReadCount += 1;
    this.notificationsLoadedCount += 1;
    this.notifications.splice(0, 0, notification);
    this.notificationsInfo.emit({ notifications: this.notifications, notificationsNotReadCount: this.notificationsNotReadCount, allNotificationsCounter: this.allNotificationsCounter });
  }

  async notificationsFromDB(user: Contact): Promise<NotificationsInfo | undefined> {
    await lastValueFrom(
      this.api.rpc<ListResponse<ListNotifications> & { allNotificationsCounter: number }>(
        endpoints.listNotifications,
        { filters: { notifiedUserId: user.id }, orderBy: { fieldName: 'creationDate', order: 'DESC' }, take: 20, skip: this.notificationsLoadedCount },
        null
      )
    ).then((res) => {
      res.list.map((n) => {
        this.notifications.push(n);
        this.notificationsLoadedCount += 1;
        if (n && n.status === NotificationStatus.SENT) this.notificationsNotReadCount += 1;
        this.allNotificationsCounter = res.allNotificationsCounter;
      });
    });

    return { notifications: this.notifications, notificationsNotReadCount: this.notificationsNotReadCount, allNotificationsCounter: this.allNotificationsCounter };
  }
}

// Web socket
export const WEB_SOCKET_CONNECTION = 'connect';
export const WEB_SOCKET_DISCONNECTION = 'disconnect';
// Web socket events
export const WEB_SOCKET_THALOS_NOTIFICATIONS_EVENT = 'thalos-notifications';
export const WEB_SOCKET_THALOS_ENTITY_VERSION_EVENT = 'thalos-entity-version';
export const WEB_SOCKET_THALOS_GET_USERS_EVENT = 'thalos-get-users';
export const WEB_SOCKET_THALOS_MESSAGES_EVENT = 'thalos-messages';

export type NotificationsInfo = {
  notifications: ListNotifications[];
  notificationsNotReadCount: number | null;
  allNotificationsCounter?: number | null;
};
export type SocketSessions = {
  socketId: string;
  userId: number;
  username: string;
  firstName: string;
  lastName: string;
  channelId: string;
  entityId: number;
  entityNumber: string;
  entityType: number;
  url: string;
};
export type ThalosMessage = { subject: string; message: string; time: number; userGroups?: UserGroup[]; toastStyle: ToastStyle; defaultMessage: DefaultMessage };
export type UserConnectInfo = Omit<SocketSessions, 'socketId'>;
export type OnlineUsersInfo = { webSocketSessions: SocketSessions[] };
export type EntityVersionEvent = Subset<SocketSessions, 'entityId' | 'entityNumber' | 'entityType' | 'url', 'username'> & {
  channelId?: string;
};

export enum ThalosDashboardSubject {
  TABS_REFRESH_REQUIRED = 'Thalos tabs refresh required',
  UPGRADE_REQUIRED = 'Thalos upgrade required',
  THALOS_MESSAGE = 'Thalos message',
  THALOS_DEPLOYMENT_SCHEDULED = 'Thalos deployment scheduled',
}

export enum ThalosDashboardMessage {
  REFRESH_TABS = 'Upgrade completed. Please, refresh tabs (CTRL + SHIFT + R). Thanks.',
  UPGRADE_IN_PROCESS = 'Dear user, we will deploy some changes that may cause intermittent disruptions in Thalos over the next few minutes.',
  UPGRADE_COMPLETED = 'Upgrade completed.',
  DEPLOYMENT_SCHEDULED = 'Please, be adivsed that at 5:30 P.M. we will be deploying changes to Thalos. Thanks.',
}
