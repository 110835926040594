<form [formGroup]="form">
  <div class="row">
    <p>Booking: {{ booking.number }}</p>
    <p *ngIf="areMultipleContracts && isContractRequired" class="text-center fw-bold text-danger">
      There are MULTIPLE Sales Contracts on this Booking, please check them out below and select at least one.
    </p>
    <p *ngIf="requiredIfNoContractData" class="text-center fw-bold text-danger">Unable to generate Pre-Advisory for this Alert Style. Booking Contract data not found.</p>
    <vertical-field [name]="'Alert Style'" class="col-12">
      <simple-dropdown [formControlName]="'templateId'" [data]="bookingAdvisoryTemplates" [textField]="'label'" [valueField]="'value'" [valuePrimitive]="true"> </simple-dropdown>
    </vertical-field>
    <vertical-field [name]="'Sales Contracts'" class="col-12" *ngIf="isContractRequired">
      <simple-dropdown [formControlName]="'contractId'" [data]="contractsDropdown" [textField]="'label'" [valueField]="'value'" [valuePrimitive]="true"> </simple-dropdown>
    </vertical-field>
  </div>
</form>
