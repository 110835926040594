import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable, Observer } from 'rxjs';
import { ModalFormComponent } from 'src/app/core/services/selector-popup.service';
import { markFormGroupTouched } from 'src/lib/helperFunctions';
import { Booking, ShipmentPurchaseSaleData } from 'src/lib/newBackendTypes';
import { TypedFormGroup } from 'src/lib/typedForms';
import { bookingAdvisoryTemplates, BookingPreAdvisoryForm, BookingPreAdvisoryPrefill, BoookingAdvisoryTemplate, GenerateBookingPreAdvisoryEmail } from 'src/lib/flex/forms/bookingPreAdvisory';
import { EnumLabels } from 'src/lib/generics';
import { conditionalValidators } from 'src/lib/genericValidators';

@Component({
  selector: 'booking-pre-advisory',
  templateUrl: './booking-pre-advisory.component.html',
})
export class BookingPreAdvisoryComponent implements OnInit, ModalFormComponent<GenerateBookingPreAdvisoryEmail, BookingPreAdvisoryPrefill> {
  form: TypedFormGroup<BookingPreAdvisoryForm>;
  popup = true;
  popupObservable: Observable<GenerateBookingPreAdvisoryEmail>;
  popupObserver: Observer<GenerateBookingPreAdvisoryEmail>;

  booking: Booking;
  contractsData: ShipmentPurchaseSaleData[] = [];
  bookingAdvisoryTemplates = bookingAdvisoryTemplates;
  contractsDropdown: EnumLabels<number> = [];
  areMultipleContracts = false;

  constructor() {
    this.form = new TypedFormGroup<BookingPreAdvisoryForm>({
      contractId: new UntypedFormControl(null),
      templateId: new UntypedFormControl(null, [Validators.required]),
    });

    this.form.get('contractId').setValidators(conditionalValidators(() => this.isContractRequired, Validators.required, this.contractsValidator()));

    this.form
      .get('templateId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((res: BoookingAdvisoryTemplate) => {
        this.form.controls['contractId'].updateValueAndValidity({ onlySelf: true });
      });

    this.popupObservable = new Observable((sub) => {
      this.popupObserver = sub;
    });
  }

  ngOnInit(): void {}

  prefillForm(values: BookingPreAdvisoryPrefill) {
    this.booking = values.booking;
    this.contractsData = values.contractsData;
    this.contractsDropdown = this.contractsData.map((item) => ({ value: item.saleContractKey, label: `${item.saleContractNumber}` }));
    this.areMultipleContracts = this.contractsData.length > 1;
  }

  allowSubmit() {
    markFormGroupTouched(this.form);
    return this.form.valid && !this.requiredIfNoContractData;
  }

  submit(): GenerateBookingPreAdvisoryEmail {
    markFormGroupTouched(this.form);
    if (this.form.invalid || this.requiredIfNoContractData) return;

    const formVal = this.form.value;
    return {
      contractId: this.isContractRequired ? formVal.contractId : this.isRequiredContractByTemplate ? this.contractsData[0].saleContractKey : null,
      templateId: formVal.templateId,
      bookingId: this.booking.id,
    };
  }

  contractsValidator() {
    return (control: AbstractControl) => {
      if (!this.form) return null;
      const templateId = this.form.controls.templateId.value;

      if (!templateId) return null;
      else return control.value.length > 1 && templateId !== BoookingAdvisoryTemplate.CUSTOMER ? { custom: 'Multiple Contracts are not allowed for this Alert Style' } : null;
    };
  }

  get isContractRequired(): boolean {
    return this.isRequiredContractByTemplate && this.areMultipleContracts;
  }

  get isRequiredContractByTemplate(): boolean {
    return (
      !!this.form &&
      (this.form.controls.templateId.value === BoookingAdvisoryTemplate.ORIGINAL ||
        this.form.controls.templateId.value === BoookingAdvisoryTemplate.CUSTOMER ||
        this.form.controls.templateId.value === BoookingAdvisoryTemplate.CUSTOMER_WAREHOUSE_BRZ ||
        this.form.controls.templateId.value === BoookingAdvisoryTemplate.WAREHOUSE_USA)
    );
  }

  get requiredIfNoContractData(): boolean {
    return this.contractsData && this.contractsData.length === 0 && this.isRequiredContractByTemplate;
  }
}
