import { EnumLabels } from '../generics';
import { enumOptionsFromEnum, firstDayOfMonth } from '../helperFunctions';
import { ContractClass, MarketCotation, PhysicalPricingType, PricingQualifier } from './contract';
import { ContractLine, LinePriceType } from './contractLine';
import { DraftLine } from './draftLine';
import { DraftPricing } from './draftPricing';
import { MarketValuationHeader } from './marketValuation';

export type baseContractPricing = {
  id: number;
  contractId: number;
  lineNumber: number;
  qpType: QPType | null;
  qpStartDate: Date | null;
  qpEndDate: Date | null;
  qpStatus: number | null;
  notes: string | null;
  quotationPeriodTypeId: PricingQualifier | null;
  cotation: MarketCotation;
  priceTermId: number;
  marketId: number;
  pricingType: PhysicalPricingType;
  percentage: number;
  spread: number;
  marketValuationId: number | null;
  marketValuation: MarketValuationHeader;
};

/**
 * PC_PRICING
 */
export type ContractPricing = baseContractPricing & {
  line?: ContractLine;
};

type qpData = Pick<baseContractPricing, 'qpType' | 'qpStartDate' | 'qpEndDate'>;

export enum QPType {
  CUSTOM_DATES = 1, // Arbitrary dates
  DAY_OF = 2, // Value of shipment date
  MONTH = 3, // Monthly Average of shipment month
  PRIOR_MONTH = 4, // Monthly Average of month prior to shipment month
  SECOND_PRIOR_MONTH = 5, // Monthly Average of second month prior to shipment month
  FOLLOWING_MONTH = 6, // Monthly Average of month following shipment month
  SECOND_FOLLOWING_MONTH = 7, // Monthly Average of 2nd month following shipment month
  THIRD_WEDNESDAY = 8, // Monday prior to 3rd Wednesday of contractual shipment month
}

export const QPTypes: EnumLabels<QPType> = [
  { value: QPType.CUSTOM_DATES, label: 'Custom Dates' },
  { value: QPType.DAY_OF, label: 'Day of' },
  { value: QPType.MONTH, label: 'M' },
  { value: QPType.PRIOR_MONTH, label: 'M-1' },
  { value: QPType.SECOND_PRIOR_MONTH, label: 'M-2' },
  { value: QPType.FOLLOWING_MONTH, label: 'M+1' },
  { value: QPType.SECOND_FOLLOWING_MONTH, label: 'M+2' },
  { value: QPType.THIRD_WEDNESDAY, label: 'Third Wednesday' },
];

export enum QPStatus {
  PENDING = 1,
  QUOTING = 2,
  BOOKED = 3,
  EXECUTED = 4,
  CANCELLED = 5,
}

export const QPStatuses = enumOptionsFromEnum(QPStatus);

export type FormulaContractPricing = {
  priceType: LinePriceType.FORMULA;
  priceValuationId: number;
  priceDiscount: number;
  pricePercentage: number;
  priceId?: number;
  premiumValuationId: number | null;
  premiumDiscount: number;
  premiumPercentage: number;
  premiumId?: number;
  fixedPremium: number;
  mtmValuationId: number;
  givenFX: number | null;
} & qpData;

export type FixedContractPricing = {
  priceType: LinePriceType.FIXED;
  amount: number;
  hedgePrice: number | null;
  mtmValuationId: number | null;
  mtmDifferential: number | null;
  givenFX: number | null;
} & qpData;

/**
 * priceType - line.priceCompletelyFixed
 * amount - line.price
 * fixedPremium - line.premium (in formula prices I think)
 * priceValuationId - pcPricing[0].marketId
 * priceDiscount - pcPricing[0].spread
 * pricePercentage - pcPricing[0].percentage
 * qpStartDate - pcPricing[0].qpStartDate
 * qpEndDate - pcPricing[0].qpEndDate
 * premiumValuationId - pcPricing[1].marketId
 * premiumDiscount - pcPricing[1].spread
 * premiumPercentage - pcPricing[1].percentage
 * mtmValuationId - mtmEntries[0].valuationid
 * mtmDifferential - mtmEntries[0].differential
 * hedgePrice - mtmEntries[0].hedgePrice (assuming this is being added)
 */
export type ContractFormPricing = FormulaContractPricing | FixedContractPricing;

export function ContractFormPricingToUpdateContractPricing(formPricing: ContractFormPricing, contractClass: ContractClass): UpdateContractPricingValues {
  let create = ContractFormPricingToContractPricing(formPricing, contractClass);

  let update: UpdateContractPricingValues = { ...create };

  if (update.priceType === LinePriceType.FORMULA && formPricing.priceType === LinePriceType.FORMULA && update.pricing.length > 0) {
    if (!!formPricing.priceId) update.pricing[0] = { ...update.pricing[0], id: formPricing.priceId };
    if (update.pricing.length > 1) {
      if (formPricing.premiumId) update.pricing[1] = { ...update.pricing[1], id: formPricing.premiumId };
    }
  }
  return update;
}
export function ContractFormPricingToContractPricing(formPricing: ContractFormPricing, contractClass: ContractClass): ContractPricingValues {
  if (formPricing.priceType === LinePriceType.FORMULA) {
    let pricing: CreatePricingRequest[] = [];

    let qpData =
      contractClass === ContractClass.QP
        ? {
            qpType: formPricing.qpType ?? null,
            qpStartDate: formPricing.qpStartDate ? formPricing.qpStartDate : null,
            qpEndDate: formPricing.qpEndDate ? formPricing.qpEndDate : null,
          }
        : {
            qpType: null,
            qpStartDate: null,
            qpEndDate: null,
            qpStatus: null,
          };

    pricing.push({
      marketValuationId: formPricing.priceValuationId,
      percentage: formPricing.pricePercentage,
      spread: formPricing.priceDiscount,
      ...qpData,
    });

    if (formPricing.premiumValuationId) {
      pricing.push({
        marketValuationId: formPricing.premiumValuationId,
        percentage: formPricing.premiumPercentage,
        spread: formPricing.premiumDiscount,
        qpType: null,
        qpStartDate: null,
        qpEndDate: null,
      });
    }

    let values: ContractPricingValues = {
      priceType: LinePriceType.FORMULA,
      pricing,
      premium: formPricing.fixedPremium,
      mtmValuationId: formPricing.mtmValuationId,
      givenFX: formPricing.givenFX,
    };

    return values;
  } else {
    let values: ContractPricingValues = {
      priceType: formPricing.priceType,
      price: formPricing.amount,
      premium: 0,
      givenFX: formPricing.givenFX,
    };
    if (contractClass === ContractClass.H || contractClass === ContractClass.QP) {
      values.hedgePrice = formPricing.hedgePrice;
      values.mtmValuationId = formPricing.mtmValuationId;
    }

    return values;
  }
}
export function ContractPricingToContractFormPricing(line: ContractLine | DraftLine): ContractFormPricing {
  if (line.priceCompletelyFixed && line.pricing && line.pricing.length > 0) {
    let pricing: (ContractPricing | DraftPricing)[] = line.pricing;
    let formula = pricing.find((p) => {
      return p.pricingType === PhysicalPricingType.PRICE;
    });
    let price: FormulaContractPricing = {
      priceType: LinePriceType.FORMULA,
      priceValuationId: formula.marketValuationId,
      fixedPremium: line.premium,
      priceDiscount: formula.spread,
      pricePercentage: formula.percentage,
      priceId: formula.id,
      premiumId: null,
      premiumValuationId: null,
      premiumPercentage: null,
      premiumDiscount: null,
      mtmValuationId: null,
      qpType: formula.qpType,
      qpEndDate: formula.qpEndDate,
      qpStartDate: formula.qpStartDate,
      givenFX: line.givenFX,
    };
    let premium = pricing.find((p) => {
      return p.pricingType === PhysicalPricingType.PREMIUM;
    });
    if (premium) {
      price.premiumValuationId = premium.marketValuationId;
      price.premiumPercentage = premium.percentage;
      price.premiumDiscount = premium.spread;
      price.premiumId = premium.id;
    }
    if (line.mtmValuationId) {
      price.mtmValuationId = line.mtmValuationId;
    }

    return price;
  } else {
    let price: FixedContractPricing = {
      priceType: LinePriceType.FIXED,
      amount: line.price,
      hedgePrice: null,
      mtmDifferential: null,
      mtmValuationId: null,
      qpType: null,
      qpEndDate: null,
      qpStartDate: null,
      givenFX: line.givenFX,
    };
    if (line.mtmValuationId) {
      price.mtmValuationId = line.mtmValuationId;
      price.hedgePrice = line.hedgePrice;
      price.mtmDifferential = line.mtmDifferential;
    }
    return price;
  }
}

// Function to calculate Monday before the third Wednesday
export function getMondayBeforeThirdWednesday(date: Date | string): Date {
  const convertedDate = typeof date === 'string' ? new Date(date) : date;
  const thirdWednesday = firstDayOfMonth(convertedDate);
  let wednesdayCounter = 0;

  // Find the third Wednesday of the month
  while (wednesdayCounter < 3) {
    if (thirdWednesday.getUTCDay() === 3) wednesdayCounter++;
    if (wednesdayCounter < 3) thirdWednesday.setUTCDate(thirdWednesday.getUTCDate() + 1);
  }

  // Go back to the previous Monday
  const mondayBefore = new Date(thirdWednesday);
  mondayBefore.setUTCDate(thirdWednesday.getUTCDate() - 2);

  return mondayBefore;
}

// Relative month dates calculated once
export function getQPHedgeRelativeMonthDays(date: Date | string): { priorMonth: Date; secondPriorMonth: Date; followingMonth: Date; secondFollowingMonth: Date } {
  const convertedDate = typeof date === 'string' ? new Date(date) : date;
  const priorMonth = new Date(Date.UTC(convertedDate.getUTCFullYear(), convertedDate.getUTCMonth() - 1, 1));
  const secondPriorMonth = new Date(Date.UTC(convertedDate.getUTCFullYear(), convertedDate.getUTCMonth() - 2, 1));
  const followingMonth = new Date(Date.UTC(convertedDate.getUTCFullYear(), convertedDate.getUTCMonth() + 1, 1));
  const secondFollowingMonth = new Date(Date.UTC(convertedDate.getUTCFullYear(), convertedDate.getUTCMonth() + 2, 1));

  return { priorMonth, secondPriorMonth, followingMonth, secondFollowingMonth };
}

export type CreatePricingRequest = Pick<ContractPricing, 'marketValuationId' | 'percentage' | 'spread' | 'qpType' | 'qpStartDate' | 'qpEndDate'>;

export type UpdatePricingRequest = Partial<CreatePricingRequest> & Pick<ContractPricing, 'id'>;

export type ContractPricingValues =
  | {
      priceType: LinePriceType.FORMULA;
      pricing: CreatePricingRequest[];
      givenFX: number | null;
      premium: number;
      hedgePrice?: number | null;
      mtmValuationId?: number | null;
    }
  | (Pick<ContractLine, 'price'> & {
      priceType: LinePriceType.FIXED;
      premium: 0;
      givenFX: number | null;
      hedgePrice?: number | null;
      mtmValuationId?: number | null;
    });

export type UpdateContractPricingValues =
  | {
      priceType: LinePriceType.FORMULA;
      premium: number;
      pricing: (CreatePricingRequest | UpdatePricingRequest)[];
      givenFX: number | null;
      hedgePrice?: number | null;
      mtmValuationId?: number | null;
    }
  | (Pick<ContractLine, 'price'> & {
      priceType: LinePriceType.FIXED;
      premium: 0;
      givenFX: number | null;
      hedgePrice?: number | null;
      mtmValuationId?: number | null;
    });
