<div style="height: 100%; display: flex; flex-direction: column; position: relative" (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)" (dragleave)="dragLeaveHandler($event)">
  <div class="drag-overlay" [ngClass]="{ 'file-hover': fileHover }"></div>
  <kendo-treelist [data]="activeData" style="flex-grow: 1; max-height: calc(100% - 40px)">
    <kendo-treelist-column [expandable]="true" title="Name" [width]="250" [field]="'name'">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <span style="cursor: pointer" (click)="itemDoubleClicked(dataItem)">
          <i [ngClass]="itemClass(dataItem)" style="font-size: 16px"></i>
          <span> {{ dataItem.thalosDisplayName }}</span>
          <i *ngIf="dataItem.error" style="font-size: 16px" class="k-color-error fas fa-exclamation-triangle error-label active-error" [title]="dataItem.error"></i>
        </span>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Size" [width]="150">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <span *ngIf="dataItem.size">{{ dataItem.size | filesize }}</span>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Location" [width]="200">
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <span>{{ dataItem.locationName }}</span>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Upload Date" [width]="200">
      <ng-template kendoTreeListCellTemplate let-dataItem let-i="rowIndex">
        <span *ngIf="dataItem.uploadDate">{{ dataItem.uploadDate | date : 'MM/d/yy' }}</span>
        <span *ngIf="dataItem.lastModifiedDateTime">{{ dataItem.lastModifiedDateTime | date : 'MM/d/yy' }}</span>
        <span *ngIf="dataItem.lastModifiedBy">
          by
          {{ dataItem.lastModifiedBy.user?.displayName || dataItem.lastModifiedBy.application?.displayName }}
        </span>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column title="Actions" [width]="200">
      <ng-template kendoTreeListCellTemplate let-dataItem let-i="rowIndex">
        <!--Thalos Documents-->
        <ng-container *ngIf="!!dataItem.storageType">
          <!--Unlink Button-->
          <button *ngIf="unlinkDocumentAuthorized" type="button" kendoButton [icon]="'unlink'" (click)="unlinkItem(i)"></button>
          <button *ngIf="dataItem.storageType === 2" type="button" kendoButton (click)="rowFileSelect.clickSelectFile()">Replace</button>
        </ng-container>
        <!--Sharepoint-->
        <ng-container *ngIf="!!dataItem?.parentReference">
          <!--List Versions-->
          <sharepoint-version-menu *ngIf="!!dataItem.lastModifiedBy" [driveItem]="dataItem" (downloadVersion)="itemDoubleClicked(dataItem, $event)"> </sharepoint-version-menu>
          <!--Delete item in Sharepoint-->
          <button *ngIf="!dataItem?.toLink" type="button" kendoButton [icon]="'trash'" (click)="deleteItem(i)"></button>
          <!--Upload new version-->
          <button *ngIf="!dataItem?.toLink" type="button" kendoButton (click)="rowFileSelect.clickSelectFile()">Replace</button>
        </ng-container>

        <!--Cancel Button, for elements not yet saved-->
        <button *ngIf="!!dataItem?.toUpload || !!dataItem?.toLink || !!dataItem?.toReplace" type="button" kendoButton [icon]="'cancel'" (click)="cancelItem(i)"></button>
        <!--Delete Button for Sharepoint Docs-->
        <file-select-widget style="display: none" [showButton]="false" [allowMultiple]="false" (fileSelected)="fileSelectedRow($event, dataItem)" #rowFileSelect></file-select-widget>
      </ng-template>
    </kendo-treelist-column>
  </kendo-treelist>
</div>
<div class="d-flex" style="flex-direction: row-reverse">
  <button kendoButton type="button" (click)="openParentFolder()" *ngIf="parentFolder">Open Entity Folder</button>
  <button kendoButton type="button" (click)="clickLinkFolder()" *ngIf="linkFolderAuthorized">Link Folder</button>
  <button kendoButton type="button" (click)="clickAddFile()">Add File</button>
  <button kendoButton type="button" (click)="clickPrintDocument()" *ngIf="generateDocAuthorized">Print Document</button>
  <button kendoButton type="button" (click)="clickSaveChanges()" *ngIf="entityId && (uploadAuthorized || updateAuthorized) && _readonlyMode && changesMade">Save Changes</button>
</div>
<file-select-widget [showButton]="false" (fileSelected)="fileSelected($event)" #fileSelect></file-select-widget>
