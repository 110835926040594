<div [formGroup]="formulaForm">
  <div class="row">
    <vertical-field [name]="'Price Type'" class="col-xl-2 col-lg-2 col-md-3 col-sm-6">
      <simple-dropdown [data]="priceTypes" [formControlName]="'priceType'" [valuePrimitive]="true" [valueField]="'value'" [textField]="'label'" [readonly]="readonly"> </simple-dropdown>
    </vertical-field>
    <ng-container *ngIf="priceType === 1">
      <vertical-field class="col-lg-2 col-md-4 col-sm-4 col-10" [name]="'Price'">
        <numerictextbox-wrapper class="vertical-input-dropdown-with-btn" formControlName="amount" [fieldType]="2" [format]="dollarFormat" [decimals]="4" [readonly]="readonly"></numerictextbox-wrapper>
        <div class="unit-label">{{ currencyPerUnit }}</div>
      </vertical-field>
    </ng-container>
    <ng-container *ngIf="qpHedged && priceType === 2">
      <vertical-field class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12" name="QP Type">
        <simple-dropdown [data]="qpTypes" [valuePrimitive]="true" [valueField]="'value'" [textField]="'label'" formControlName="qpType" [readonly]="readonly"> </simple-dropdown>
      </vertical-field>
      <vertical-field class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12" name="QP Start Date">
        <datepicker-wrapper formControlName="qpStartDate" [readonly]="readonly || qpDatesReadOnly"> </datepicker-wrapper>
      </vertical-field>
      <vertical-field class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12" name="QP End Date">
        <datepicker-wrapper formControlName="qpEndDate" [readonly]="readonly || qpDatesReadOnly || qpEndDateReadOnly"> </datepicker-wrapper>
      </vertical-field>
    </ng-container>
  </div>
  <ng-container *ngIf="priceType === 2">
    <label for="premiumMarket">Market</label>
    <div class="row">
      <inline-field class="col-xl-3 col-lg-4 col-md-4 col-sm-7 col-9">
        <div class="d-flex left-parenthesis">
          <market-valuation-dropdown style="width: 100%" formControlName="priceValuationId" [priceValuationId]="valuationId" [existEntity]="existEntity" [readonly]="readonly">
          </market-valuation-dropdown>
        </div>
      </inline-field>
      <ng-container *ngIf="!formulaForm.get('priceValuationId').value">
        <div class="col-1 right-parenthesis"></div>
      </ng-container>

      <ng-container #name *ngIf="formulaForm.get('priceValuationId').value">
        <inline-field class="col-lg-1 col-md-2 col-sm-2 col-3">
          <kendo-dropdownlist [readonly]="readonly" [data]="['+', '-']" [formControl]="priceDiscountSign"> </kendo-dropdownlist>
        </inline-field>
        <inline-field class="col-lg-2 col-md-3 col-sm-4 col-6 mt-3 mt-md-0">
          <numerictextbox-wrapper
            style="flex-grow: 1"
            (blur)="blurPriceDiscount()"
            [decimals]="4"
            [format]="dollarFormat"
            formControlName="priceDiscount"
            [readonly]="readonly"
          ></numerictextbox-wrapper>
          <div class="right-parenthesis unit-label">{{ marketCurrencyPerUnit }}</div>
        </inline-field>
        <inline-field class="col-xl-1 col-md-2 col-sm-4 col-6 mt-3 mt-md-0">
          <div class="bold-text">x</div>
          <numerictextbox-wrapper
            style="flex-grow: 1"
            class="show-percentage"
            formControlName="pricePercentage"
            [max]="200"
            [min]="0.0001"
            [format]="percentFormat"
            [decimals]="4"
            [readonly]="readonly"
          ></numerictextbox-wrapper>
        </inline-field>
      </ng-container>
    </div>
    <div class="form-divider-line-dotted"></div>
    <label for="premiumMarket">Premium Market</label>
    <div class="row">
      <kendo-dropdownlist [readonly]="readonly" *ngIf="!!formulaForm.get('premiumValuationId').value" class="col-sm-1 col-2" [data]="['+', '-']" [formControl]="premiumMarketSign"></kendo-dropdownlist>
      <inline-field class="col-xl-3 col-lg-4 col-md-4 col-sm-7 col-11">
        <div class="d-flex left-parenthesis">
          <market-valuation-dropdown
            [premium]="true"
            style="width: 100%"
            formControlName="premiumValuationId"
            [premiumValuationId]="premiumValuationId"
            [existEntity]="existEntity"
            [readonly]="readonly"
          >
          </market-valuation-dropdown>
        </div>
      </inline-field>
      <ng-container *ngIf="!formulaForm.get('premiumValuationId').value">
        <div class="col-1 right-parenthesis"></div>
      </ng-container>
      <ng-container #name *ngIf="formulaForm.get('premiumValuationId').value">
        <inline-field class="col-sm-1 col-2">
          <kendo-dropdownlist [readonly]="readonly" [data]="['+', '-']" [formControl]="premiumDiscountSign"> </kendo-dropdownlist>
        </inline-field>
        <inline-field class="col-lg-2 col-md-3 col-4">
          <numerictextbox-wrapper (blur)="blurPremiumDiscount()" [fieldType]="2" [decimals]="4" [format]="dollarFormat" formControlName="premiumDiscount" [readonly]="readonly">
          </numerictextbox-wrapper>
          <div class="right-parenthesis unit-label">{{ premiumMarketCurrencyPerUnit }}</div>
        </inline-field>
        <inline-field class="col-xl-1 col-md-2 col-4">
          <div class="bold-text">x</div>
          <numerictextbox-wrapper
            class="show-percentage"
            style="width: 100%"
            formControlName="premiumPercentage"
            [readonly]="readonly"
            [max]="200"
            [min]="0.0001"
            [format]="percentFormat"
            [decimals]="4"
          ></numerictextbox-wrapper>
        </inline-field>
      </ng-container>
    </div>
    <div class="form-divider-line-dotted"></div>
    <label for="Premium">Premium</label>
    <div class="row">
      <kendo-dropdownlist [readonly]="readonly" class="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-4" [data]="['+', '-']" [formControl]="fixedPremiumSign"> </kendo-dropdownlist>
      <inline-field class="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-4">
        <numerictextbox-wrapper (blur)="blurPremium()" [fieldType]="2" [decimals]="4" [format]="dollarFormat" formControlName="fixedPremium" [readonly]="readonly"></numerictextbox-wrapper>
      </inline-field>
      <div class="col-md-1 col-3" style="font-size: small; padding: 0; height: 29px; line-height: 29px">{{ premiumCurrencyPerUnit }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="!!hedged">
    <div class="form-divider-line-dotted"></div>
    <div class="row">
      <vertical-field [name]="'MTM Market'" class="col-xl-2 col-lg-3 col-md-4">
        <market-valuation-dropdown style="display: inline-flex" formControlName="mtmValuationId" [priceValuationId]="valuationId" [existEntity]="existEntity" [readonly]="readonly">
        </market-valuation-dropdown>
      </vertical-field>
      <ng-container *ngIf="priceType === 1">
        <vertical-field [name]="'Hedge Price'" class="col-lg-2 col-md-3">
          <numerictextbox-wrapper
            class="vertical-input-dropdown-with-btn"
            [fieldType]="2"
            [decimals]="4"
            [format]="dollarFormat"
            formControlName="hedgePrice"
            [readonly]="readonly"
          ></numerictextbox-wrapper>
          <div class="unit-label">{{ mtmMarketCurrencyPerUnit }}</div>
        </vertical-field>
        <vertical-field [name]="'MTM Differential'" class="col-lg-2 col-md-3">
          <numerictextbox-wrapper
            class="vertical-input-dropdown-with-btn"
            [readonly]="true"
            [decimals]="4"
            [format]="dollarFormat"
            formControlName="mtmDifferential"
            [readonly]="readonly"
          ></numerictextbox-wrapper>
          <div class="unit-label">{{ mtmMarketCurrencyPerUnit }}</div>
        </vertical-field>
      </ng-container>
      <vertical-field [name]="'Foreign Exchange Rate'" class="col-xl-1 col-lg-2 col-md-4 col-sm-6" *ngIf="mtmMarketCurrency?.id !== currency?.id">
        <numerictextbox-wrapper formControlName="givenFX" [decimals]="4" [format]="{ style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 4 }"> </numerictextbox-wrapper>
      </vertical-field>
    </div>
  </ng-container>
</div>
