import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { Booking, PropertyDocument, ShipmentPurchaseSaleData, SourceEntityType } from 'src/lib/newBackendTypes';
import { checkPrefillCallback, DynamicFormConstant, DynamicFormType, openFormCallback, prefillCallback, submitFormCallback } from './types';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { EnumLabels } from 'src/lib/generics';
import { BookingPreAdvisoryComponent } from 'src/app/+modules/+logistics/booking/booking-pre-advisory/booking-pre-advisory.component';

const bookingPreAdvisoryPrefillCallback: prefillCallback<BookingPreAdvisoryPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const bookingId = Array.isArray(id) ? id[0] : id;
      const booking = await api.run<Booking>(endpoints.getBooking, { filters: { id: bookingId } });
      if (!booking) return 'Unable to generate booking Pre Advisory: Booking not found.';
      if (!booking.containers || booking.containers.length === 0) return 'Unable to generate booking Pre Advisory: Containers not found.';
      const containerIds = booking.containers.map((item) => item.id);
      const shipments = await api.run<ListResponse<PropertyDocument>>(endpoints.listShipments, { filters: { containerId: containerIds } });
      if (shipments.list && shipments.list.length === 0) return 'Unable to generate booking Pre Advisory: Shipments not found.';
      const shipmentsContractData = await api.run<ListResponse<ShipmentPurchaseSaleData>>(endpoints.listShipmentContractData, { filters: { shipmentId: shipments.list.map((s) => s.id) } });

      const filteredList = shipmentsContractData.list.reduce((acc, item) => {
        if (item.saleContractKey && !acc.some((i) => i.saleContractKey === item.saleContractKey)) {
          acc.push(item);
        }
        return acc;
      }, []);

      return { booking, contractsData: filteredList };
    })()
  );
};

const bookingPreAdvisoryPrefillCheck: checkPrefillCallback<BookingPreAdvisoryPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  return of(true);
};

const bookingPreAdvisoryOpenForm: openFormCallback<BookingPreAdvisoryPrefill, BookingPreAdvisoryForm> = (delegate, id, prefill) => {
  const selector = delegate.getService('selector');
  return selector.openForm<GenerateBookingPreAdvisoryEmail, BookingPreAdvisoryComponent, BookingPreAdvisoryPrefill>(BookingPreAdvisoryComponent, {
    title: `Booking Pre-Advisory: ${prefill.booking.number}`,
    initializer: (c) => {
      c.booking = prefill.booking;
      c.contractsData = prefill.contractsData;
    },
    prefillValue: prefill,
    maxWidth: '90%',
    width: 500,
  });
};

const bookingPreAdvisorySubmit: submitFormCallback<BookingPreAdvisoryPrefill, BookingPreAdvisoryForm> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<{ subject: string; html: string }>((resolve, reject) => {
      (async () => {
        try {
          const response = await api.run<{ subject: string; html: string }>(endpoints.generateBookingPreAdvisoryEmail, result, null);
          return resolve(response);
        } catch (err) {
          return reject(err);
        }
      })();
    })
      .then((res) => {
        if (res) {
          downloadBookingPreAdvisoryEmail(res.subject, res.html);
          return prompt.htmlDialog('Success', `<div style="white-space: pre">Booking Pre Advisory template succesfully downloaded.</div>`);
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

const downloadBookingPreAdvisoryEmail = (subject: string, html: string) => {
  const emlContent = ['MIME-Version: 1.0', `Subject: ${subject}`, 'Content-Type: text/html; charset=UTF-8', '', html].join('\r\n');

  const blob = new Blob([emlContent], { type: 'message/rfc822' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${subject}.eml`;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const bookingPreAdvisoryPreset: DynamicFormConstant<BookingPreAdvisoryPrefill, BookingPreAdvisoryForm> = {
  allowMultipleRows: false,
  value: DynamicFormType.BOOKING_PRE_ADVISORY,
  label: 'Booking Pre-Advisory',
  title: 'Booking Pre-Advisory',
  endpoints: [endpoints.getBooking, endpoints.listShipments, endpoints.listShipmentContractData, endpoints.generateBookingPreAdvisoryEmail],
  getPrefill: bookingPreAdvisoryPrefillCallback,
  checkPrefill: bookingPreAdvisoryPrefillCheck,
  entityType: SourceEntityType.FREIGHT_BOOKING_KEY,
  openForm: bookingPreAdvisoryOpenForm,
  submitForm: bookingPreAdvisorySubmit,
  width: 500,
};

export type GenerateBookingPreAdvisoryEmail = {
  contractId: number | null;
  bookingId: number;
  templateId: BoookingAdvisoryTemplate;
};

export type BookingPreAdvisoryForm = {
  contractId: number;
  templateId: BoookingAdvisoryTemplate;
};

export type BookingPreAdvisoryPrefill = {
  booking: Booking;
  contractsData: ShipmentPurchaseSaleData[];
};

export enum BoookingAdvisoryTemplate {
  ORIGINAL = 1,
  CUSTOMER = 2, // Deprecated
  WAREHOUSE_CCIC = 3, // Deprecated
  CUSTOMER_WAREHOUSE_BRZ = 4,
  WAREHOUSE_BRZ = 5,
  WAREHOUSE_SPA = 6,
  WAREHOUSE_USA = 7,
}

export const bookingAdvisoryTemplates: EnumLabels<BoookingAdvisoryTemplate> = [
  { value: BoookingAdvisoryTemplate.ORIGINAL, label: 'Original' },
  { value: BoookingAdvisoryTemplate.CUSTOMER_WAREHOUSE_BRZ, label: 'Customer / Warehouse (BRZ)' },
  { value: BoookingAdvisoryTemplate.WAREHOUSE_BRZ, label: 'Warehouse (Brazil)' },
  { value: BoookingAdvisoryTemplate.WAREHOUSE_SPA, label: 'Warehouse (Spain)' },
  { value: BoookingAdvisoryTemplate.WAREHOUSE_USA, label: 'Warehouse (USA)' },
];
