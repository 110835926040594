import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter, map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { endpoints } from '../../../lib/apiEndpoints';
import { UploadDocumentParams } from '../../../lib/newBackendTypes/document';
import { ThalosApiService } from './thalos-api.service';
import { PromptService } from './prompt.service';
import { DialogResult } from '@progress/kendo-angular-dialog';

@Injectable()
export class FileUploadService {
  constructor(private api: ThalosApiService, private http: HttpClient, private prompt: PromptService) {}

  upload<T>(file: File, params: UploadDocumentParams): Observable<T | number> {
    const formData = new FormData();
    formData.append('file', file);

    let urlPrams = [`${params.entityType}`, `${params.entityId}`];
    if (params.documentId) urlPrams.push(`${params.documentId}`);

    let endpoint = '/uploadDocument';
    let uri = `${environment.apiHost}${endpoint}/${urlPrams.join('/')}`;

    return this.http.post<T>(uri, formData, { reportProgress: true, observe: 'events' }).pipe(
      catchError((error) => this.api.handleError<any>(error, { environmentName: environment.name, defaultValue: false, endpoint: uri })),
      map((event) => {
        if (event === false) return 'Failed';
        if (event.type === HttpEventType.DownloadProgress) {
          return Math.floor((event.loaded / event.total) * 100);
        } else if (event.type === HttpEventType.Response) {
          return event.body;
        } else return null;
      }),
      filter((res) => res !== null),
      startWith(0)
    );
  }

  download(): DialogResult {
    return this.prompt.textDialog('Error', 'Unable to download document: Document is unavailable.');
  }

  printContractPDF(id: number): Observable<HttpResponse<ArrayBuffer>> {
    let args: { id: number } = { id };

    let uri = `${environment.apiHost}${endpoints.printContract}`;
    return this.http
      .post(uri, { args }, { responseType: 'arraybuffer', observe: 'response' })
      .pipe(catchError((error) => this.api.handleError(error, { environmentName: environment.name, defaultValue: null, endpoint: uri })));
  }

  downloadFile(data: ArrayBuffer, fileName: string) {
    let blob = new Blob([data]);
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
  }
}
